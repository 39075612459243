package de.l4zs.tools.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.icons.fa.FaEnvelope
import com.varabyte.kobweb.silk.components.icons.fa.FaGithub
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.palette.button
import com.varabyte.kobweb.silk.theme.colors.palette.color
import com.varabyte.kobweb.silk.theme.colors.palette.toPalette
import de.l4zs.tools.components.layouts.MainPageContentStyle
import de.l4zs.tools.components.layouts.PageLayout
import de.l4zs.tools.components.widgets.IconButton
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*

@Page
@Composable
fun HomePage() {
    val palette = ColorMode.current.toPalette()

    PageLayout("Home", MainPageContentStyle) {
        Row(
            modifier = Modifier.fillMaxHeight(),
            verticalAlignment = Alignment.CenterVertically,
        ) {
            Column(
                modifier = Modifier.fillMaxWidth(),
                horizontalAlignment = Alignment.Start,
            ) {
                H1(
                    attrs = Modifier
                        .fontWeight(FontWeight.Light)
                        .color(palette.color.toRgb().copyf(alpha = 0.75f))
                        .margin(0.px)
                        .fontSize(4.cssRem)
                        .toAttrs()
                ) {
                    Text("Hi, I'm ")
                    B(
                        attrs = Modifier.fontWeight(FontWeight.Bold).color(palette.button.default).toAttrs()
                    ) {
                        Text("l4zs")
                    }
                }
                P {
                    Text("Developer and System Administrator based in Germany")
                    Br()
                    Text("Although I am proficient in many programming languages, I primarily specialize in Kotlin and Java.")
                    Br()
                    Text("Currently studying Computer Science at University of Münster.")
                }
                Row(
                    horizontalArrangement = Arrangement.spacedBy(1.cssRem),
                    verticalAlignment = Alignment.CenterVertically,
                ) {
                    A(
                        href = "https://github.com/l4zs",
                        attrs = Modifier.attrsModifier { attr("target", "_blank") }.toAttrs()
                    ) {
                        IconButton({ }) {
                            FaGithub(size = IconSize.XL)
                        }
                    }
                    A(href = "mailto:business@l4zs.de") {
                        IconButton({ }) {
                            FaEnvelope(size = IconSize.XL)
                        }
                    }
                }
            }
        }
    }
}

import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.DeferringHost
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { de.l4zs.tools.pages.HomePage() }
        ctx.router.register("/tools/") { de.l4zs.tools.pages.tools.IndexPage() }
        ctx.router.register("/tools/text/counter/") {
                de.l4zs.tools.pages.tools.text.TextCounterPage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerStyle("markdown", de.l4zs.tools.components.layouts.MarkdownStyle)
        ctx.theme.registerStyle("page-content", de.l4zs.tools.components.layouts.PageContentStyle)
        ctx.theme.registerStyle("main-page-content",
                de.l4zs.tools.components.layouts.MainPageContentStyle)
        ctx.theme.registerStyle("tool-content", de.l4zs.tools.components.layouts.ToolContentStyle)
        ctx.theme.registerStyle("hide-scroll-bar",
                de.l4zs.tools.components.layouts.HideScrollBarStyle)
        ctx.theme.registerStyle("boxed-tags", de.l4zs.tools.components.layouts.BoxedTagsStyle)
        ctx.theme.registerStyle("tool-box-wrapper",
                de.l4zs.tools.components.layouts.ToolBoxWrapperStyle)
        ctx.theme.registerStyle("footer", de.l4zs.tools.components.sections.FooterStyle)
        ctx.theme.registerStyle("nav-header", de.l4zs.tools.components.sections.NavHeaderStyle)
        ctx.theme.registerStyle("boxed-tooltip",
                de.l4zs.tools.components.tool.text.BoxedTooltipStyle)
        ctx.theme.registerStyle("filled-hover", de.l4zs.tools.components.tool.text.FilledHoverStyle)
        ctx.theme.registerStyle("pseudo-box-sizing",
                de.l4zs.tools.components.tool.text.PseudoBoxSizingStyle)
        ctx.theme.registerVariant("-circle", de.l4zs.tools.CircleButtonVariant
        )
        ctx.theme.registerVariant("-uncolored", de.l4zs.tools.UncoloredButtonVariant
        )
        ctx.theme.registerKeyframes("side-menu-slide-in",
                de.l4zs.tools.components.sections.SideMenuSlideInAnim)
        de.l4zs.tools.initColorMode(ctx)
        de.l4zs.tools.initSiteStyles(ctx)
        de.l4zs.tools.initTheme(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "l4zs"))
    renderComposable(rootElementId = "root") {
        de.l4zs.tools.AppEntry {
            router.renderActivePage { DeferringHost { it() } }
        }
    }
}

package de.l4zs.tools.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.forms.InputDefaults
import com.varabyte.kobweb.silk.components.forms.InputKind
import com.varabyte.kobweb.silk.components.forms.InputStyle
import com.varabyte.kobweb.silk.style.CssStyleVariant
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.attributes.readOnly

@Composable
fun TextArea(
    text: String,
    onTextChange: (String) -> Unit = {},
    modifier: Modifier = Modifier,
    variant: CssStyleVariant<InputKind>? = InputDefaults.Variant,
    placeholder: String? = null,
    readOnly: Boolean = false,
) {
    org.jetbrains.compose.web.dom.TextArea(attrs = InputStyle.toModifier(variant)
        .then(modifier)
        .toAttrs {
            value(text)
            placeholder?.let { placeholder(it) }
            if (readOnly) readOnly()
            onInput { onTextChange(it.value) }
        }
    )
}

package de.l4zs.tools.pages.tools.convert

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/tools/convert/text-binary/index")
@Composable
fun TextToBinaryPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("tools/convert/TextToBinary.md", mapOf("title" to listOf("Text ⇔ Binary"), "description" to listOf("Encode text to binary or decode binary to text."), "tags" to listOf("binary", "encode", "decode")))) {
        de.l4zs.tools.components.layouts.ToolLayout("Text ⇔ Binary") {
            org.jetbrains.compose.web.dom.H1(attrs = { id("text-binary") }) {
                org.jetbrains.compose.web.dom.Text("Text ⇔ Binary")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Encode Text to Binary or decode Binary to Text.")
            }
            de.l4zs.tools.components.tool.convert.Binary()
            org.jetbrains.compose.web.dom.H3(attrs = { id("usage") }) {
                org.jetbrains.compose.web.dom.Text("Usage")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Click the \"Encode to Binary\" or \"Decode to Text\" button to switch between the two modes. Type some text into the box above and see the Binary en-/decoding in real-time.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("what-is-binary") }) {
                org.jetbrains.compose.web.dom.Text("What is Binary?")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Binary is a base-2 number system that uses only two digits: 0 and 1. In binary, each digit represents a power of 2, with the rightmost digit representing 2^0, the next 2^1, then 2^2, and so on. While humans typically use base-10 (decimal) numbers, computers use binary because it's easier to implement with electronic circuits that have two states: on or off.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("privacy") }) {
                org.jetbrains.compose.web.dom.Text("Privacy")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("This tool runs entirely in your browser and does not send any data to a server.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Experiencing issues with this tool? Please ")
                    com.varabyte.kobweb.silk.components.navigation.Link("mailto:business@l4zs.de") {
                        org.jetbrains.compose.web.dom.Text("report them")
                    }
                    org.jetbrains.compose.web.dom.Text(".")
                }
            }
        }
    }
}

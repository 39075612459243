package de.l4zs.tools.util

import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextOverflow
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.display
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.textOverflow
import com.varabyte.kobweb.compose.ui.modifiers.whiteSpace
import com.varabyte.kobweb.compose.ui.styleModifier
import org.jetbrains.compose.web.css.DisplayStyle

fun Modifier.maxLines(lines: Int) = whiteSpace(WhiteSpace.PreLine)
    .textOverflow(TextOverflow.Ellipsis)
    .overflow(Overflow.Hidden)
    .display(DisplayStyle("-webkit-box"))
    .styleModifier {
        property("-webkit-line-clamp", "$lines")
        property("-webkit-box-orient", "vertical")
    }
package de.l4zs.tools.components.tool.text

import androidx.compose.runtime.*
import com.varabyte.kobweb.browser.dom.ElementTarget
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.dom.ref
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.FilledInputVariant
import com.varabyte.kobweb.silk.components.forms.InputStyle
import com.varabyte.kobweb.silk.components.overlay.Tooltip
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.style.vars.color.BorderColorVar
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.palette.color
import com.varabyte.kobweb.silk.theme.colors.palette.focusOutline
import com.varabyte.kobweb.silk.theme.colors.palette.input
import com.varabyte.kobweb.silk.theme.colors.palette.toPalette
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextArea
import org.w3c.dom.HTMLElement
import kotlin.time.Duration
import kotlin.time.Duration.Companion.minutes

val BoxedTooltipStyle = CssStyle {
    base {
        Modifier
            .border(1.px, LineStyle.Solid, BorderColorVar.value())
            .color(colorMode.toPalette().focusOutline.darkened(0.25f))
            .fontWeight(FontWeight.Bold)
            .background(colorMode.toPalette().input.filled)
            .fontSize(1.cssRem)
            .borderRadius(0.5.cssRem)
            .padding(0.2.cssRem, 0.5.cssRem)
    }

    hover {
        Modifier
            .background(colorMode.toPalette().input.filledHover)
    }
}

val FilledHoverStyle = CssStyle {
    hover {
        Modifier
            .background(colorMode.toPalette().input.filledHover)
    }
}

val PseudoBoxSizingStyle = CssStyle {
    cssRule("::before") {
        Modifier.boxSizing(BoxSizing.BorderBox)
    }
    cssRule("::after") {
        Modifier.boxSizing(BoxSizing.BorderBox)
    }
}

@Composable
private fun BoxedTooltipText(modifier: Modifier, text: String, tooltip: String) {
    Box(BoxedTooltipStyle.toModifier().then(modifier)) {
        Text(text)
    }
    Tooltip(ElementTarget.PreviousSibling, tooltip)
}

@Composable
fun TextCounter(
    initialText: String = "",
    placeholder: String = "Enter text here",
) {
    val colorMode = ColorMode.current

    var text by remember { mutableStateOf(initialText) }
    var analysis by remember { mutableStateOf(TextAnalysis(0, 0, 0, 0, emptyList())) }
    var showAllWordsAndGroups by remember { mutableStateOf(false) }
    var highlight: String? by remember { mutableStateOf(null) }

    var backdropElement: HTMLElement? by remember { mutableStateOf(null) }
    var highlightsElement: HTMLElement? by remember { mutableStateOf(null) }
    val highlightColor = colorMode.toPalette().focusOutline.toRgb().copyf(alpha = 0.3f)

    Column(verticalArrangement = Arrangement.spacedBy(1.cssRem)) {
        Box(
            modifier = PseudoBoxSizingStyle.toModifier()
                .fillMaxWidth()
                .height(20.cssRem)

                .display(DisplayStyle.Block)
                .margin(0.px, autoLength)
                .transform { translateZ(0.px) }
        ) {
            Box(
                PseudoBoxSizingStyle.toModifier().then(InputStyle.toModifier(FilledInputVariant))
                    .fillMaxWidth()
                    .height(20.cssRem)
                    .position(Position.Absolute)
                    .zIndex(1)
                    .border(1.px, LineStyle.Solid, BorderColorVar.value())
                    .borderRadius(0.5.cssRem)
                    .background(colorMode.toPalette().input.filled)
                    .overflow(Overflow.Auto)
                    .pointerEvents(PointerEvents.None)
                    .styleModifier {
                        property("transition", "transform 1s")
                    },
                ref = ref { backdropElement = it }
            ) {
                Box(
                    modifier = PseudoBoxSizingStyle.toModifier()
                        .styleModifier {
                            property("font", "20px/28px monospace")
                        }
                        .display(DisplayStyle.Block)
                        .overflow(Overflow.Auto)
                        .letterSpacing(1.px)
                        .padding(1.cssRem)
                        .whiteSpace(WhiteSpace.PreWrap)
                        .styleModifier {
                            property("word-wrap", "break-word")
                        }
                        .color(Colors.Transparent),
                    ref = ref { highlightsElement = it }
                ) {
                }
            }

            TextArea(attrs = PseudoBoxSizingStyle.toModifier().then(InputStyle.toModifier(FilledInputVariant))
                .styleModifier {
                    property("font", "20px/28px monospace")
                }
                .fillMaxWidth()
                .height(20.cssRem)
                .letterSpacing(1.px)
                .padding(1.cssRem)
                .display(DisplayStyle.Block)
                .position(Position.Absolute)
                .zIndex(2)
                .margin(0.px)
                .border(1.px, LineStyle.Solid, BorderColorVar.value())
                .borderRadius(0.5.cssRem)
                .color(colorMode.toPalette().color)
                .background(Colors.Transparent)
                .overflow(Overflow.Auto)
                .resize(Resize.None)
                .styleModifier {
                    property("transition", "transform 1s")
                }
                .toAttrs {
                    value(text)
                    placeholder(placeholder)
                    spellCheck(true)
                    onInput {
                        text = it.value.replace(" ", " ")
                        analysis = TextAnalysis(text)
                        if (analysis.wordsAndGroups.none { pair -> pair.first == highlight }) {
                            highlight = null
                        }
                        if (highlight != null) {
                            highlightsElement!!.innerHTML = applyHighlight(text, highlight!!, highlightColor)
                        } else {
                            highlightsElement!!.innerHTML = text
                        }
                    }
                    onScroll {
                        backdropElement!!.scrollTop = (it.target as HTMLElement).scrollTop
                    }
                })
        }

        Row(
            modifier = Modifier.flexWrap(FlexWrap.Wrap).gap(1.cssRem),
            verticalAlignment = Alignment.CenterVertically,
        ) {
            SpanText("Words: ${analysis.wordCount}")
            SpanText("Characters: ${analysis.charCount}")
            SpanText("Sentences: ${analysis.sentenceCount}")
            SpanText("Paragraphs: ${analysis.paragraphCount}")
            SpanText("Reading: ${analysis.readingTime}")
            SpanText("Speaking: ${analysis.speakingTime}")
        }

        if (analysis.wordsAndGroups.isNotEmpty()) {
            SpanText("Most used words and groups:")

            Row(
                modifier = Modifier.flexWrap(FlexWrap.Wrap).gap(0.5.cssRem),
                verticalAlignment = Alignment.CenterVertically,
            ) {
                if (showAllWordsAndGroups) {
                    analysis.wordsAndGroups
                } else {
                    analysis.wordsAndGroups.take(20)
                }.forEach { (content, count) ->
                    BoxedTooltipText(
                        Modifier.onClick {
                            if (highlight == content) {
                                highlight = null
                                highlightsElement!!.innerHTML = text
                            } else {
                                highlight = content
                                highlightsElement!!.innerHTML = applyHighlight(text, content, highlightColor)
                            }
                        }.thenIf(
                            highlight == content,
                            Modifier.background(highlightColor)
                        ), content, "Used $count times"
                    )
                }
                if (showAllWordsAndGroups) {
                    Button({ showAllWordsAndGroups = false }) {
                        Text("Show ${analysis.wordsAndGroups.size - 20} less")
                    }
                } else if (analysis.wordsAndGroups.size > 20) {
                    Button({ showAllWordsAndGroups = true }) {
                        Text("Show ${analysis.wordsAndGroups.size - 20} more")
                    }
                }
            }
        }
    }
}

private val WHITESPACE_REGEX = Regex("\\s+", RegexOption.MULTILINE)
private val SENTENCE_END_CHAR_REGEX = Regex("[.!?]$", RegexOption.MULTILINE)
private val SENTENCE_END_REGEX = Regex("[.!?](?= [A-Z0-9]|\$)", RegexOption.MULTILINE)
private val PARAGRAPH_REGEX = Regex("\n+", RegexOption.MULTILINE)

private data class TextAnalysis(
    val wordCount: Int,
    val charCount: Int,
    val sentenceCount: Int,
    val paragraphCount: Int,
    val wordsAndGroups: List<Pair<String, Int>>,
) {
    companion object {
        private fun groupedCountFor(words: List<String>, window: Int = 1): Map<String, Int> {
            return words
                .windowed(window)
                .map {
                    if (it.first().contains("\n")) {
                        listOf(it.first().replace("\n", "")) + it.drop(1)
                    } else {
                        it
                    }
                }
                .filterNot { list -> list.any { it.contains("\n") } }
                .map { it.joinToString(" ") }
                .groupingBy { it }
                .eachCount()
                .toList()
                .filter { it.second >= 3 }
                .sortedByDescending { it.second }
                .toMap()
        }

        operator fun invoke(text: String): TextAnalysis {
            val whiteSpaceSplit = text.split(WHITESPACE_REGEX)
                .filterNot { word -> word.isBlank() || word.all { it == '.' } }
                .map { it.replace(SENTENCE_END_CHAR_REGEX, "") }
            val spaceSplit = text
                .replace("\n", " \n")
                .split(" ")
                .filterNot { word -> word.isBlank() || word.all { it == '.' } }
                .map { it.lowercase() }
            val words = groupedCountFor(whiteSpaceSplit)
            var wordsAndGroups = words + groupedCountFor(spaceSplit, 2)
            var last = wordsAndGroups
            var i = 3

            while (last.isNotEmpty() && i <= 4) {
                last = groupedCountFor(spaceSplit, i)
                wordsAndGroups = wordsAndGroups + last
                i++
            }

            val (wordCount, charCount, sentenceCount, paragraphCount) = if (text.isBlank()) {
                listOf(0, 0, 0, 0)
            } else {
                listOf(
                    whiteSpaceSplit.filter { it.isNotBlank() }.size,
                    text.length,
                    text.split(SENTENCE_END_REGEX).count { it.isNotEmpty() },
                    text.split(PARAGRAPH_REGEX).count(),
                )
            }

            return TextAnalysis(
                wordCount,
                charCount,
                sentenceCount,
                paragraphCount,
                wordsAndGroups.toList().sortedByDescending { it.second },
            )
        }
    }

    private fun calculateDuration(wordsPerMinute: Int): Duration {
        return (wordCount.toDouble() / wordsPerMinute).minutes
    }

    private fun formatDuration(duration: Duration): String {
        val hours = duration.inWholeHours
        val minutes = duration.inWholeMinutes % 60
        val seconds = duration.inWholeSeconds % 60
        return if (hours > 0) {
            "$hours h $minutes min $seconds s"
        } else if (minutes > 0) {
            "$minutes min $seconds s"
        } else {
            "$seconds s"
        }
    }

    val readingDuration: Duration = calculateDuration(238)
    val readingTime: String = formatDuration(readingDuration)
    val speakingDuration: Duration = calculateDuration(150)
    val speakingTime: String = formatDuration(speakingDuration)
}

private fun applyHighlight(text: String, highlight: String, color: Color): String {
    return text.replace(Regex("(?<=\\s|^)($highlight)(?=\\s|\$|[.!?])", setOf(RegexOption.IGNORE_CASE, RegexOption.MULTILINE)))
    { result ->
        result.value.replace(
            Regex(highlight, RegexOption.IGNORE_CASE),
        ) {
            "<span style=\"border-radius: .25rem; color: transparent; background: $color\">${it.value}</span>"
        }
    }
}

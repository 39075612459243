package de.l4zs.tools.components.layouts

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.palette.color
import com.varabyte.kobweb.silk.theme.colors.palette.toPalette
import de.l4zs.tools.components.sections.ToolsList
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun ToolsPage(content: @Composable () -> Unit) {
    val colorMode = ColorMode.current

    PageLayout("Tools", MainPageContentStyle) {

        Column(
            verticalArrangement = Arrangement.spacedBy(4.cssRem)
        ) {
            Div {
                H1(
                    attrs = Modifier
                        .color(colorMode.toPalette().color.toRgb().copyf(alpha = 0.75f))
                        .fontSize(3.cssRem)
                        .fontWeight(400)
                        .margin(bottom = 2.5.cssRem)
                        .lineHeight(1.2)
                        .toAttrs()
                ) {
                    Text("Tools")
                }
                P {
                    Text("This page features a collection of practical tools designed for various tasks and occasional needs. (More tools coming soon)")
                }
            }
            ToolsList()
        }
    }
}

package de.l4zs.tools

import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.forms.ButtonStyle
import com.varabyte.kobweb.silk.components.forms.ButtonVars
import com.varabyte.kobweb.silk.components.layout.HorizontalDividerStyle
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.init.registerStyleBase
import com.varabyte.kobweb.silk.style.addVariantBase
import com.varabyte.kobweb.silk.theme.modifyStyleBase
import org.jetbrains.compose.web.css.*

@InitSilk
fun initSiteStyles(ctx: InitSilkContext) {
    ctx.stylesheet.registerStyle("") {
        cssRule("::-webkit-scrollbar") {
            Modifier
                .width(6.px)
                .background(Colors.Transparent)
        }

        cssRule("::-webkit-scrollbar-thumb") {
            Modifier
                .background(darkTheme.primary)
                .borderRadius(8.px)
                .styleModifier {
                    property("-webkit-box-shadow", "inset 0 0 0 4px ${darkTheme.text.toRgb().copyf(alpha = 0.3f)}")
                }
        }

        cssRule("::-webkit-scrollbar-track") {
            Modifier.background(Colors.Transparent)
        }
    }

    ctx.stylesheet.registerStyle("html") {
        base {
            Modifier.background(darkTheme.background)
        }

        cssRule(CSSMediaQuery.MediaFeature("prefers-reduced-motion", StylePropertyValue("no-preference"))) {
            Modifier.scrollBehavior(ScrollBehavior.Smooth)
        }
    }

    ctx.stylesheet.registerStyleBase("body") {
        Modifier
            .fontFamily("Ubuntu", "Roboto", "Arial", "Helvetica", "sans-serif")
            .fontSize(18.px)
            .lineHeight(1.5)
    }

    ctx.stylesheet.registerStyleBase("code") {
        Modifier.fontFamily("Ubuntu Mono", "Roboto Mono", "Lucida Console", "Courier New", "monospace")
    }

    ctx.theme.modifyStyleBase(HorizontalDividerStyle) {
        Modifier.fillMaxWidth()
    }
}

val CircleButtonVariant = ButtonStyle.addVariantBase {
    Modifier.padding(0.px).borderRadius(50.percent)
}

val UncoloredButtonVariant = ButtonStyle.addVariantBase {
    Modifier.setVariable(ButtonVars.BackgroundDefaultColor, Colors.Transparent)
}

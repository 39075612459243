package de.l4zs.tools.components.tool.convert

import androidx.compose.runtime.*
import de.l4zs.tools.util.decodeBinaryToText
import de.l4zs.tools.util.encodeTextToBinary

@Composable
fun Binary() {
    EncodeDecodeConversion(
        "Text",
        "Binary",
        { it.encodeTextToBinary() },
        { it.decodeBinaryToText() },
    )
}

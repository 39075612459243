package de.l4zs.tools.pages.tools.text

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/tools/text/counter/index")
@Composable
fun TextCounterPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("tools/text/TextCounter.md", mapOf("title" to listOf("Text counter"), "description" to listOf("Count words, characters, sentences, and paragraphs in text. Calculate reading and speaking times. Find common words and groups of words."), "tags" to listOf("word count", "character count", "sentence count", "paragraph count", "reading time", "speaking time", "common words")))) {
        de.l4zs.tools.components.layouts.ToolLayout("Text counter") {
            org.jetbrains.compose.web.dom.H1(attrs = { id("text-counter") }) {
                org.jetbrains.compose.web.dom.Text("Text counter")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Count words, characters, sentences, and paragraphs in text. Calculate reading and speaking times. Find common words and groups of words.")
            }
            de.l4zs.tools.components.tool.text.TextCounter()
            org.jetbrains.compose.web.dom.H3(attrs = { id("usage-and-features") }) {
                org.jetbrains.compose.web.dom.Text("Usage and Features")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Type some text into the box above and the counts will update in real-time. You will see the number of words, characters, sentences, and paragraphs in the text. Additionally, average reading and speaking times are calculated.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("The most commonly used words and groups of words are also displayed. A click on one of these will highlight all instances of that word or group of words in the text. Hovering over one of these will show the number of times it appears in the text.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("how-it-works") }) {
                org.jetbrains.compose.web.dom.Text("How it works")
            }
            com.varabyte.kobweb.silk.components.display.Callout(type = com.varabyte.kobweb.silk.components.display.CalloutType.NOTE, label = null, variant = com.varabyte.kobweb.silk.components.display.CalloutDefaults.Variant) {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text(" This tool runs in your browser and does not send any data to a server. Due to this, processing may be slower for huge texts.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("The text is analyzed using regular expressions:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("A word is defined as a sequence of something separated by whitespace characters.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("The characters count excludes non-space whitespace characters.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("A sentence is defined as a sequence of something separated by a period, exclamation mark, or question mark.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("A paragraph is defined as a sequence of something separated by a newline character.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("The reading time is calculated using the average reading speed of 238 words per minute.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("The speaking time is calculated using the average speaking speed of 150 words per minute.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Common words and groups of words are calculated using a frequency analysis:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Words or groups that occur more than three times are considered common.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("They are displayed in descending order of frequency.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("While shown in lowercase, the detection and highlighting is case-insensitive.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Experiencing issues with this tool? Please ")
                    com.varabyte.kobweb.silk.components.navigation.Link("mailto:business@l4zs.de") {
                        org.jetbrains.compose.web.dom.Text("report them")
                    }
                    org.jetbrains.compose.web.dom.Text(".")
                }
            }
        }
    }
}

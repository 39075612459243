package de.l4zs.tools.pages.tools

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/tools/")
@Composable
fun IndexPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("tools/index.md", mapOf("0" to listOf("Text counter", "Count words, characters, sentences, and paragraphs in text. Calculate reading and speaking times. Find common words and groups of words.", "/tools/text/counter/", "word count", "character count", "sentence count", "paragraph count", "reading time", "speaking time", "common words")))) {
        de.l4zs.tools.components.layouts.ToolsPage() {
        }
    }
}

package de.l4zs.tools.components.tool.convert

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.Resize
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.ButtonVars
import com.varabyte.kobweb.silk.components.forms.FilledInputVariant
import com.varabyte.kobweb.silk.components.icons.fa.FaCopy
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.selectors.active
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.style.vars.color.BorderColorVar
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.palette.color
import com.varabyte.kobweb.silk.theme.colors.palette.input
import com.varabyte.kobweb.silk.theme.colors.palette.toPalette
import de.l4zs.tools.CircleButtonVariant
import de.l4zs.tools.UncoloredButtonVariant
import de.l4zs.tools.components.widgets.TextArea
import kotlinx.browser.window
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Text

private enum class EncodeDecodeConversionMode {
    ENCODE,
    DECODE,
}

val EncodeDecodeConversionModeRowStyle = CssStyle {
    base {
        Modifier
            .fillMaxWidth()
            .flexWrap(FlexWrap.Wrap)
            .gap(1.cssRem, 2.cssRem)
    }

    Breakpoint.MD {
        Modifier.flexWrap(FlexWrap.Nowrap)
    }
}

val EncodeDecodeConversionModeCopyButtonStyle = CssStyle {
    base {
        Modifier.setVariable(ButtonVars.FontSize, 1.em)
            .background(colorMode.toPalette().input.filled)
    }

    hover {
        Modifier.backgroundColor(ButtonVars.BackgroundHoverColor.value())
    }

    active {
        Modifier.backgroundColor(ButtonVars.BackgroundPressedColor.value())
    }
}

@Composable
fun EncodeDecodeConversion(from: String, to: String, encode: (String) -> String, decode: (String) -> String) {
    val colorMode = ColorMode.current

    var text by remember { mutableStateOf("") }
    var mode by remember { mutableStateOf(EncodeDecodeConversionMode.ENCODE) }
    val encoded by remember(text) { mutableStateOf(encode(text)) }
    val decoded by remember(text) { mutableStateOf(decode(text)) }

    Column(
        modifier = Modifier.fillMaxWidth(),
        verticalArrangement = Arrangement.spacedBy(2.cssRem),
    ) {
        Row(
            modifier = EncodeDecodeConversionModeRowStyle.toModifier()
        ) {
            Button(
                onClick = { mode = EncodeDecodeConversionMode.ENCODE },
                modifier = Modifier.fillMaxWidth(),
                enabled = mode != EncodeDecodeConversionMode.ENCODE,
            ) {
                Text("Encode to $to")
            }
            Button(
                onClick = { mode = EncodeDecodeConversionMode.DECODE },
                modifier = Modifier.fillMaxWidth(),
                enabled = mode != EncodeDecodeConversionMode.DECODE,
            ) {
                Text("Decode to $from")
            }
        }
        Row(
            modifier = EncodeDecodeConversionModeRowStyle.toModifier(),
            horizontalArrangement = Arrangement.spacedBy(2.cssRem)
        ) {
            Box(
                modifier = Modifier.fillMaxWidth().position(Position.Relative)
            ) {
                TextArea(
                    text,
                    { text = it },
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(20.cssRem)
                        .letterSpacing(1.px)
                        .padding(1.cssRem)
                        .display(DisplayStyle.Block)
                        .border(1.px, LineStyle.Solid, BorderColorVar.value())
                        .borderRadius(0.5.cssRem)
                        .color(colorMode.toPalette().color)
                        .overflow(Overflow.Auto)
                        .resize(Resize.None),
                    placeholder = if (mode == EncodeDecodeConversionMode.ENCODE) "Enter $from to encode to $to" else "Enter $to to decode to $from",
                    variant = FilledInputVariant
                )
            }
            Box(
                modifier = Modifier.fillMaxWidth().position(Position.Relative)
            ) {
                TextArea(
                    if (mode == EncodeDecodeConversionMode.ENCODE) encoded else decoded,
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(20.cssRem)
                        .letterSpacing(1.px)
                        .padding(1.cssRem)
                        .display(DisplayStyle.Block)
                        .border(1.px, LineStyle.Solid, BorderColorVar.value())
                        .borderRadius(0.5.cssRem)
                        .color(colorMode.toPalette().color)
                        .overflow(Overflow.Auto)
                        .resize(Resize.None)
                        .background(colorMode.toPalette().input.filledHover),
                    variant = FilledInputVariant,
                    readOnly = true
                )
                Box(
                    modifier = Modifier.position(Position.Absolute)
                        .top(1.cssRem)
                        .right(1.cssRem)
                ) {
                    Button(
                        onClick = {
                            val textToCopy = if (mode == EncodeDecodeConversionMode.ENCODE) encoded else decoded
                            window.navigator.clipboard.writeText(textToCopy)
                        },
                        EncodeDecodeConversionModeCopyButtonStyle.toModifier(),
                        variant = CircleButtonVariant.then(UncoloredButtonVariant)
                    ) {
                        FaCopy()
                    }
                }
            }
        }
    }
}

package de.l4zs.tools.components.tool.convert

import androidx.compose.runtime.*
import de.l4zs.tools.util.decodeBase64ToText
import de.l4zs.tools.util.encodeTextToBase64

@Composable
fun Base64() {
    EncodeDecodeConversion(
        "Text",
        "Base64",
        { it.encodeTextToBase64() },
        { it.decodeBase64ToText() },
    )
}

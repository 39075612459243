package de.l4zs.tools

import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.graphics.lightened
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.theme.colors.palette.*

data class Theme(
    val text: Color,
    val background: Color,
    val primary: Color,
    val secondary: Color,
    val accent: Color,
)

/**
 * [preview](https://www.realtimecolors.com/dashboard?colors=191815-f2f1ed-998f74-bcc8b4-99b093)
 */
val lightTheme = Theme(
    text = Color.rgb(0x191815),
    background = Color.rgb(0xf2f1ed),
    primary = Color.rgb(0x998f74),
    secondary = Color.rgb(0xbcc8b4),
    accent = Color.rgb(0x99b093),
)

/**
 * [preview](https://www.realtimecolors.com/dashboard?colors=eae9e6-12110d-a99c47-40baa8-03ba27)
 */
val darkTheme = Theme(
    text = Color.rgb(0xeae9e6),
    background = Color.rgb(0x12110d),
    primary = Color.rgb(0xa99c47),
    secondary = Color.rgb(0x40baa8),
    accent = Color.rgb(0x03ba27),
)

fun applyLightTheme(ctx: InitSilkContext) {
    ctx.theme.palettes.light.let {
        it.color = lightTheme.text
        it.background = lightTheme.background
        it.border = lightTheme.primary.lightened(0.2f)
        it.focusOutline = lightTheme.accent
        it.overlay = Colors.Black
        it.placeholder = lightTheme.text.lightened(0.3f)

        it.link.let { link ->
            link.default = lightTheme.secondary.darkened(0.1f)
            link.visited = lightTheme.secondary.lightened(0.1f)
        }

        it.button.let { button ->
            button.default = lightTheme.primary
            button.hover = lightTheme.primary.darkened(0.1f)
            button.focus = lightTheme.primary.darkened(0.1f)
            button.pressed = lightTheme.primary.lightened(0.1f)
        }

        it.checkbox.let { checkbox ->
            checkbox.color = lightTheme.secondary
            checkbox.background = lightTheme.background
            checkbox.hover = lightTheme.background.darkened(0.1f)
        }

        it.input.let { input ->
            input.filled = lightTheme.background.darkened(0.1f)
            input.filledFocus = lightTheme.background.darkened(0.15f)
            input.filledHover = lightTheme.background.darkened(0.05f)
            input.hoveredBorder = lightTheme.secondary
            input.invalidBorder = it.callout.caution
        }

        it.switch.let { switch ->
            switch.thumb = lightTheme.text
            switch.backgroundOff = lightTheme.background.darkened(0.1f)
            switch.backgroundOn = lightTheme.secondary
        }

        it.tab.let { tab ->
            tab.color = lightTheme.text
            tab.background = lightTheme.background.darkened(0.1f)
            tab.hover = lightTheme.background.darkened(0.15f)
            tab.pressed = lightTheme.background.darkened(0.2f)
            tab.disabled = lightTheme.background.darkened(0.05f)
            tab.selectedBackground = lightTheme.primary
            tab.selectedBorder = lightTheme.secondary
            tab.selectedColor = lightTheme.background
        }

        it.tooltip.let { tooltip ->
            tooltip.color = lightTheme.text
            tooltip.background = lightTheme.background.darkened(0.2f)
        }
    }
}

fun applyDarkTheme(ctx: InitSilkContext) {
    ctx.theme.palettes.dark.let {
        it.color = darkTheme.text
        it.background = darkTheme.background
        it.border = darkTheme.primary.darkened(0.2f)
        it.focusOutline = darkTheme.accent
        it.overlay = Colors.Black
        it.placeholder = darkTheme.text.darkened(0.3f)

        it.link.let { link ->
            link.default = darkTheme.secondary.lightened(0.1f)
            link.visited = darkTheme.secondary.darkened(0.1f)
        }

        it.button.let { button ->
            button.default = darkTheme.primary
            button.hover = darkTheme.primary.lightened(0.1f)
            button.focus = darkTheme.primary.lightened(0.1f)
            button.pressed = darkTheme.primary.darkened(0.1f)
        }

        it.checkbox.let { checkbox ->
            checkbox.color = darkTheme.secondary
            checkbox.background = darkTheme.background
            checkbox.hover = darkTheme.background.lightened(0.1f)
        }

        it.input.let { input ->
            input.filled = darkTheme.background.lightened(0.1f)
            input.filledFocus = darkTheme.background.lightened(0.15f)
            input.filledHover = darkTheme.background.lightened(0.05f)
            input.hoveredBorder = darkTheme.secondary
            input.invalidBorder = it.callout.caution
        }

        it.switch.let { switch ->
            switch.thumb = darkTheme.text
            switch.backgroundOff = darkTheme.background.lightened(0.1f)
            switch.backgroundOn = darkTheme.secondary
        }

        it.tab.let { tab ->
            tab.color = darkTheme.text
            tab.background = darkTheme.background.lightened(0.1f)
            tab.hover = darkTheme.background.lightened(0.15f)
            tab.pressed = darkTheme.background.lightened(0.2f)
            tab.disabled = darkTheme.background.lightened(0.05f)
            tab.selectedBackground = darkTheme.primary
            tab.selectedBorder = darkTheme.secondary
            tab.selectedColor = darkTheme.background
        }

        it.tooltip.let { tooltip ->
            tooltip.color = darkTheme.text
            tooltip.background = darkTheme.background.lightened(0.2f)
        }
    }
}

@InitSilk
fun initTheme(ctx: InitSilkContext) {
    // apply lightTheme and darkTheme to the context
    applyLightTheme(ctx)
    applyDarkTheme(ctx)
}

package de.l4zs.tools.util

import kotlin.io.encoding.Base64
import kotlin.io.encoding.ExperimentalEncodingApi

fun String.truncate(maxLength: Int, ellipsis: String = "..."): String {
    return if (this.length > maxLength) {
        this.substring(0, maxLength - ellipsis.length) + ellipsis
    } else {
        this
    }
}

@OptIn(ExperimentalEncodingApi::class)
fun String.encodeTextToBase64() = try {
    Base64.Default.encode(this.encodeToByteArray())
} catch (e: Exception) {
    "Invalid input"
}

@OptIn(ExperimentalEncodingApi::class)
fun String.decodeBase64ToText() = try {
    Base64.Default.decode(this).decodeToString()
} catch (e: Exception) {
    "Invalid input"
}

fun String.encodeTextToBinary() = try {
    this.toCharArray().joinToString(" ") {
        it.code.toString(2).padStart(8, '0')
    }
} catch (e: Exception) {
    "Invalid input"
}

fun String.decodeBinaryToText() = try {
    if (this.contains(" ")) {
        this.split(" ").filter { it.isNotBlank() }.joinToString("") {
            it.windowed(8, 8, true).joinToString("") { str ->
                str.toInt(2).toChar().toString()
            }
        }
    } else {
        this.split("").filter { it.isNotBlank() }.windowed(8, 8, true).joinToString("") {
            it.joinToString("").toInt(2).toChar().toString()
        }
    }
} catch (e: Exception) {
    "Invalid input"
}

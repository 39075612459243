package de.l4zs.tools.components.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.UndecoratedLinkVariant
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.palette.color
import com.varabyte.kobweb.silk.theme.colors.palette.focusOutline
import com.varabyte.kobweb.silk.theme.colors.palette.input
import com.varabyte.kobweb.silk.theme.colors.palette.toPalette
import com.varabyte.kobwebx.markdown.markdown
import de.l4zs.tools.util.maxLines
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.dom.Text

@Composable
fun ToolsList() {
    data class Tool(val title: String, val description: String, val tags: List<String>, val route: String)

    val ctx = rememberPageContext()
    val tools = ctx.markdown!!.frontMatter.map { (_, value) ->
        val (title, description, route) = value

        Tool(
            title = title,
            description = description,
            tags = value.drop(3),
            route = route
        )
    }

    Row(
        modifier = Modifier.fillMaxWidth().flexWrap(FlexWrap.Wrap).gap(2.cssRem).justifyContent(JustifyContent.Center),
        verticalAlignment = Alignment.CenterVertically,
    ) {
        tools.sortedBy { it.title }.forEach {
            ToolBox(it.title, it.description, it.tags, it.route)
        }
    }
}

val ToolBoxStyle = CssStyle {
    base {
        Modifier
            .background(colorMode.toPalette().input.filled)
            .borderRadius(0.5.cssRem)
            .padding(0.75.cssRem)
            .height(10.cssRem)
            .width(12.cssRem)
    }

    hover {
        Modifier
            .transform {
                scale(1.1f)
            }
    }
}

val ToolBoxTitleStyle = CssStyle.base {
    Modifier
        .color(colorMode.toPalette().color.toRgb().copyf(alpha = 0.75f))
        .fillMaxWidth()
        .fontSize(1.3.cssRem)
        .display(DisplayStyle.Block)
        .textAlign(TextAlign.Center)
}

val ToolBoxDescriptionStyle = CssStyle.base {
    Modifier
        .color(colorMode.toPalette().color)
        .fontSize(0.9.cssRem)
        .fontWeight(FontWeight.Light)
}

val ToolBoxTagWrapper = CssStyle {
    base {
        Modifier
            .styleModifier {
                property("-ms-overflow-style", "none")
                property("scrollbar-width", "none")
                property("-webkit-overflow-scrolling", "touch")
            }
            .fillMaxWidth()
            .padding(top = 0.5.cssRem)
            .height(Height.FitContent)
            .overflow(Overflow.Auto, Overflow.Hidden)
            .display(DisplayStyle.Flex)
            .gap(0.25.cssRem)
            .userSelect(UserSelect.None)
    }

    cssRule("::-webkit-scrollbar") {
        Modifier.display(DisplayStyle.None)
    }
}

val ToolBoxTagStyle = CssStyle {
    base {
        Modifier
            .justifyContent(JustifyContent.Center)
            .alignItems(AlignItems.Center)
            .styleModifier {
                property("flex", "none")
            }
            .height(Height.FitContent)
            .width(Width.FitContent)
            .color(colorMode.toPalette().focusOutline.darkened(0.25f))
            .fontWeight(FontWeight.Bold)
            .background(colorMode.toPalette().input.filledHover)
            .fontSize(0.8.cssRem)
            .borderRadius(0.5.cssRem)
            .padding(0.15.cssRem, 0.3.cssRem)
    }
}

@Composable
private fun ToolBox(title: String, description: String, tags: List<String>, route: String) {
    Link(
        modifier = ToolBoxStyle.toModifier(),
        path = route,
        autoPrefix = false,
        variant = UndecoratedLinkVariant
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
        ) {
            Box(
                modifier = ToolBoxTitleStyle.toModifier()
            ) {
                Text(title)
            }
            SpanText(
                description,
                modifier = ToolBoxDescriptionStyle.toModifier().maxLines(3)
            )

            Spacer()

            Box(
                modifier = ToolBoxTagWrapper.toModifier()
            ) {
                tags.forEach { tag ->
                    Box(ToolBoxTagStyle.toModifier()) {
                        Text(tag)
                    }
                }
            }
        }
    }
}

package de.l4zs.tools.pages.tools.convert

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/tools/convert/text-base64/index")
@Composable
fun TextToBase64Page() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("tools/convert/TextToBase64.md", mapOf("title" to listOf("Text ⇔ Base64"), "description" to listOf("Encode text to Base64 or decode Base64 to text."), "tags" to listOf("base64", "encode", "decode")))) {
        de.l4zs.tools.components.layouts.ToolLayout("Text ⇔ Base64") {
            org.jetbrains.compose.web.dom.H1(attrs = { id("text-base64") }) {
                org.jetbrains.compose.web.dom.Text("Text ⇔ Base64")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Encode Text to Base64 or decode Base64 to Text.")
            }
            de.l4zs.tools.components.tool.convert.Base64()
            org.jetbrains.compose.web.dom.H3(attrs = { id("usage") }) {
                org.jetbrains.compose.web.dom.Text("Usage")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Click the \"Encode to Binary\" or \"Decode to Text\" button to switch between the two modes. Type some text into the box above and see the Base64 en-/decoding in real-time.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("what-is-base64") }) {
                org.jetbrains.compose.web.dom.Text("What is Base64?")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Base64 is a binary-to-text encoding scheme that represents binary data in an ASCII string format. It's commonly used to transmit binary data over systems that are designed to handle text. It encodes three bytes of binary data into four ASCII characters using a set of 64 characters (hence the name) which includes A-Z, a-z, 0-9, and usually '+' and '/' (with '=' for padding). Base64 encoding increases the data size by approximately 33% compared to the original binary data. The encoding is fully reversible, meaning the original binary data can be recovered from the Base64 encoded string.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("privacy") }) {
                org.jetbrains.compose.web.dom.Text("Privacy")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("This tool runs entirely in your browser and does not send any data to a server.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Experiencing issues with this tool? Please ")
                    com.varabyte.kobweb.silk.components.navigation.Link("mailto:business@l4zs.de") {
                        org.jetbrains.compose.web.dom.Text("report them")
                    }
                    org.jetbrains.compose.web.dom.Text(".")
                }
            }
        }
    }
}
